import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "tileBackgroundColor", "ariaLabel", "headingText", "headingType", "subHeadingText", "paraText", "paraHeadingClassName", "subParaText", "subHeadingType", "imageSource", "imageSrcset", "imageCaption", "mobileImageUrl", "imageAltText", "imageTitle", "mediaType", "richTextEmbeddedAsset", "descriptionText", "descriptionType", "variation", "imageClassName", "headingClassName", "subHeadingClassName", "subParaClassName", "paraClassName", "linkClassName", "linkStyleType", "linkTitle", "ctaURL", "sysId", "ctaCopy", "ctaBehavior", "ctaHyperlinkType", "ctaSysId", "ctaCollection", "dataAnalytics", "children", "srcSetSetting", "isGallery", "donotLazyLoad", "categoryTitle", "contentfulLivePreviewHeadingText", "contentfulLivePreviewSubParaText", "contentfulLivePreviewDescriptionText", "customCode"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './ImageTextCard.style';
import Para from '../../atoms/Para';
import Heading from '../../atoms/Heading';
import Anchor from '../../atoms/Anchor';
import Image from '../../atoms/Image';
import constants, { mediaContentType, siteConstants } from '../../../constants/globalConstants';
import RichText from '../../atoms/RichText';
import VideoIcon from '../../../static/images/icons/PlayButton';
import Mailchimp from '../../atoms/Mailchimp';
var TextCardBlock = styled.section.withConfig({
  componentId: "sc-h1x7ke-0"
})(["", ";"], styles);
var ImageTextCard = function ImageTextCard(_ref) {
  var className = _ref.className,
    tileBackgroundColor = _ref.tileBackgroundColor,
    ariaLabel = _ref.ariaLabel,
    headingText = _ref.headingText,
    headingType = _ref.headingType,
    subHeadingText = _ref.subHeadingText,
    paraText = _ref.paraText,
    paraHeadingClassName = _ref.paraHeadingClassName,
    subParaText = _ref.subParaText,
    subHeadingType = _ref.subHeadingType,
    imageSource = _ref.imageSource,
    imageSrcset = _ref.imageSrcset,
    imageCaption = _ref.imageCaption,
    mobileImageUrl = _ref.mobileImageUrl,
    imageAltText = _ref.imageAltText,
    imageTitle = _ref.imageTitle,
    mediaType = _ref.mediaType,
    richTextEmbeddedAsset = _ref.richTextEmbeddedAsset,
    descriptionText = _ref.descriptionText,
    descriptionType = _ref.descriptionType,
    variation = _ref.variation,
    imageClassName = _ref.imageClassName,
    headingClassName = _ref.headingClassName,
    subHeadingClassName = _ref.subHeadingClassName,
    subParaClassName = _ref.subParaClassName,
    paraClassName = _ref.paraClassName,
    linkClassName = _ref.linkClassName,
    linkStyleType = _ref.linkStyleType,
    linkTitle = _ref.linkTitle,
    ctaURL = _ref.ctaURL,
    sysId = _ref.sysId,
    ctaCopy = _ref.ctaCopy,
    ctaBehavior = _ref.ctaBehavior,
    ctaHyperlinkType = _ref.ctaHyperlinkType,
    ctaSysId = _ref.ctaSysId,
    ctaCollection = _ref.ctaCollection,
    dataAnalytics = _ref.dataAnalytics,
    children = _ref.children,
    srcSetSetting = _ref.srcSetSetting,
    isGallery = _ref.isGallery,
    donotLazyLoad = _ref.donotLazyLoad,
    categoryTitle = _ref.categoryTitle,
    contentfulLivePreviewHeadingText = _ref.contentfulLivePreviewHeadingText,
    contentfulLivePreviewSubParaText = _ref.contentfulLivePreviewSubParaText,
    contentfulLivePreviewDescriptionText = _ref.contentfulLivePreviewDescriptionText,
    customCode = _ref.customCode,
    others = _objectWithoutProperties(_ref, _excluded);
  var appliedClass = variation && (variation.toLowerCase() === constants.variations.variationB.toLowerCase() || variation.toLowerCase() === constants.variations.variationC.toLowerCase()) ? variation.toLowerCase() : '';
  var tileBGColor = tileBackgroundColor && (variation === null || variation === void 0 ? void 0 : variation.toLowerCase()) === constants.variations.variationC.toLowerCase() ? tileBackgroundColor : null;
  var isVideo = mediaType && mediaType === mediaContentType.VIDEO ? true : false;
  return __jsx(TextCardBlock, {
    className: "nva-image-text-card ".concat(appliedClass, " ").concat(className),
    style: {
      background: tileBGColor
    },
    id: sysId
  }, imageSource && !isGallery && !imageSource.includes('base64') ? __jsx("div", {
    className: "nva-image-text-card__image ".concat(imageClassName)
  }, __jsx(Image, {
    src: imageSource,
    alt: imageAltText,
    title: imageTitle || imageAltText,
    srcset: imageSrcset,
    srcSetSettingImg: srcSetSetting,
    mobileImageUrl: mobileImageUrl,
    donotLazyLoad: donotLazyLoad,
    priority: srcSetSetting && srcSetSetting.priority
  }), isVideo && __jsx("span", {
    className: "nva-image-text-card__video-icon video-icon"
  }, __jsx(VideoIcon, {
    width: "54",
    height: "54"
  }))) : imageSource.includes('base64') ? __jsx("div", {
    className: "nva-image-text-card__image ".concat(imageClassName)
  }, __jsx(Image, {
    src: imageSource,
    alt: imageAltText,
    title: imageTitle || imageAltText,
    width: "100%"
  })) : '', imageSource && isGallery && __jsx(React.Fragment, null, __jsx("div", {
    className: "nva-image-text-card__image ".concat(imageClassName, " ").concat(imageCaption ? 'gallery-media-caption' : '')
  }, __jsx(Image, {
    src: imageSource,
    alt: imageAltText,
    title: imageTitle || imageAltText,
    srcset: imageSrcset,
    srcSetSettingImg: srcSetSetting,
    mobileImageUrl: mobileImageUrl,
    donotLazyLoad: donotLazyLoad
  }), isVideo && __jsx("span", {
    className: "nva-image-text-card__video-icon video-icon"
  }, __jsx(VideoIcon, {
    width: "54",
    height: "54"
  })))), categoryTitle && __jsx(Para, {
    className: "nva-image-text-card__category-title u-overline"
  }, categoryTitle), headingText && __jsx(Heading, {
    HeadingType: headingType,
    className: "nva-image-text-card__heading ".concat(headingClassName),
    contentfulLivePreview: contentfulLivePreviewHeadingText
  }, headingText), paraText && __jsx(Para, {
    className: "nva-image-text-card__para-heading ".concat(paraHeadingClassName)
  }, paraText), subHeadingText && __jsx(Heading, {
    HeadingType: subHeadingType,
    className: "nva-image-text-card__para-sub-heading ".concat(subHeadingClassName ? subHeadingClassName : '')
  }, subHeadingText), subParaText && __jsx(Para, {
    className: "nva-image-text-card__para-heading ".concat(subParaClassName),
    contentfulLivePreview: contentfulLivePreviewSubParaText
  }, subParaText), descriptionText && descriptionType && descriptionType === siteConstants.RICH_TEXT && __jsx(RichText, {
    className: "nva-image-text-card__para ".concat(paraClassName),
    jsonContent: descriptionText,
    richTextEmbeddedAsset: richTextEmbeddedAsset,
    contentfulLivePreview: contentfulLivePreviewDescriptionText
  }), descriptionText && !descriptionType && __jsx(Para, {
    className: "nva-image-text-card__para ".concat(paraClassName)
  }, descriptionText), customCode && __jsx("div", {
    className: "nva-image-text-card__custom-code"
  }, __jsx(Mailchimp, {
    mailchimpdata: customCode
  })), children && __jsx("div", {
    className: "content-container"
  }, children), ctaCopy && __jsx(Para, {
    className: linkClassName
  }, __jsx(Anchor, {
    "data-entry-id": ctaSysId,
    title: ctaCopy,
    to: ctaURL,
    className: "nva-image-text-card__anchor arrow",
    styleType: linkStyleType,
    ctaBehavior: ctaBehavior,
    hyperlinkType: ctaHyperlinkType,
    "aria-label": ariaLabel,
    "data-analytics-type": dataAnalytics && dataAnalytics.type ? dataAnalytics.type : '',
    "data-analytics-value": dataAnalytics && dataAnalytics.value ? dataAnalytics.value : '',
    "data-analytics-variation": dataAnalytics && dataAnalytics.variation ? dataAnalytics.variation : ''
  }, ctaCopy)), ctaCollection && __jsx("div", {
    className: "nva-image-text-card__cta CTAWrapper"
  }, ctaCollection.items.map(function (cta) {
    var _cta$sys;
    return __jsx(Para, {
      className: linkClassName,
      key: cta.sys.id
    }, __jsx(Anchor, {
      "data-entry-id": (_cta$sys = cta.sys) === null || _cta$sys === void 0 ? void 0 : _cta$sys.id,
      title: cta.label,
      to: cta.url,
      className: "nva-image-text-card__anchor arrow",
      styleType: linkStyleType,
      ctaBehavior: cta.behavior,
      hyperlinkType: cta.hyperlinkType,
      "aria-label": cta.ariaLabel,
      "data-analytics-type": dataAnalytics && dataAnalytics.type ? dataAnalytics.type : '',
      "data-analytics-value": dataAnalytics && dataAnalytics.value ? dataAnalytics.value : '',
      "data-analytics-variation": dataAnalytics && dataAnalytics.variation ? dataAnalytics.variation : ''
    }, cta.label));
  })));
};
ImageTextCard.defaultProps = {
  className: '',
  tileBackgroundColor: '',
  ariaLabel: '',
  headingText: '',
  subHeadingText: '',
  imageSource: '',
  imageAltText: '',
  imageTitle: '',
  descriptionText: '',
  variation: '',
  linkClassName: '',
  mediaType: mediaContentType.IMAGE,
  categoryTitle: '',
  donotLazyLoad: false
};
export default styled(ImageTextCard).withConfig({
  componentId: "sc-h1x7ke-1"
})(["", ";"], styles);
export { ImageTextCard as ImageTextCardVanilla };